<template>
    <div class="d-middle">
        <div class="tipo-user d-middle-center mr-2">
            <img :src="grupo.imagen" alt="" class="obj-cover rounded-circle border" style="width: 45px; height: 45px;" />
        </div>
        <div>
            <p class="f-17 f-500">{{ grupo.nombre }}</p>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    computed:{
        ...mapGetters({
            grupo:'chatsGrupos/grupoActivo'
        })
    },

}
</script>